import type { Table } from '@tanstack/react-table';
import SearchBar from 'app/components/SearchBar';

export interface TableSearchProps {
  searchFilter?: string;
  onSearchFilterChange?: (value: string) => void;
}

export default function TableSearch<T>({
  table,
  searchFilter,
  onSearchFilterChange,
}: { table: Table<T> } & TableSearchProps) {
  return (
    <SearchBar
      // When search state is not controlled by the passed props,
      // we default to local filtering using the table's global filter state
      value={String(searchFilter ?? table.getState().globalFilter ?? '')}
      onChange={(value) =>
        onSearchFilterChange
          ? onSearchFilterChange(value)
          : table.setGlobalFilter(value)
      }
      className="h-8 min-w-64 flex-grow max-w-[380px]"
    />
  );
}
