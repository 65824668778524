import { type Table, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

export default function TableFooter<T>({ table }: { table: Table<T> }) {
  if (
    table.getAllFlatColumns().filter((column) => !!column.columnDef.footer)
      .length === 0
  ) {
    // Don't render if no columns have footers
    return null;
  }

  return (
    <tfoot className="divide-y divide-gray-200 border-t border-gray-200">
      {table.getFooterGroups().map((footerGroup) => (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map((header) => (
            <th
              key={header.id}
              className={classNames(
                'px-2 py-3 text-left font-medium text-gray-400',
                header.column.columnDef.meta?.className,
              )}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.footer,
                    header.getContext(),
                  )}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  );
}
